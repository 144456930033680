import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Popper } from "@mui/base/Popper";
import { fontFredoka } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ZIndexLayer } from "scmp-app/lib/styles";

export const Container = styled.div`
  display: flex;

  font-family: ${fontFredoka};
`;

export const StyledBaseLink = styled(BaseLink)`
  display: block;
`;

export const StyledPopper = styled(Popper)`
  z-index: ${ZIndexLayer.AppBar};
`;

export const MenuItem = styled.div`
  position: relative;

  color: #333333;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  white-space: nowrap;
`;

type MenuItemNameProps = {
  $isActive: boolean;
};
export const MenuItemName = styled.span<MenuItemNameProps>`
  padding-block: 4px;
  padding-inline: 12px;

  color: #333333;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 25px;

  cursor: pointer;

  ${props =>
    props.$isActive &&
    css`
      border-radius: 20px;

      background: #fecc17;
    `}

  &:hover {
    border-radius: 20px;

    background: #fecc17;
  }
`;

export const SubMenuContainer = styled.div`
  position: relative;
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  padding: 24px;

  border: 1px solid #000000;
  border-radius: 6px;

  background: #ffffff;
`;

export const SubMenuItem = styled.div`
  color: #777777;
  font-size: 18px;
  font-family: ${fontFredoka};

  &:hover {
    color: #000000;
  }
`;

export const StyledArrowUp = styled.div`
  position: absolute;
  inset-block-start: -10px;
  inset-inline-start: 11%;

  inline-size: 20px;
  block-size: 20px;

  background-color: #ffffff;

  transform: rotate(45deg);
  border-block-start: #000000 solid 1px;
  border-inline-start: #000000 solid 1px;
`;
